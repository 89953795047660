<template>
    <div id="hservice">
        <div class="container-fluid py-3" style="background-color: #058CFF;">
            <div class="container service-margin my-4 text-center">
                <div class="row my-5" >
                    <div class="col-12">
                        <h3 class="tittle-service">Service</h3>
                        <center> <hr style="border-top: 4px solid #FFFFFF; width: 90px; opacity: 100;"></center>
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12">
                        <h5 class="content-service">No project is too small or too big, and no industry is off limits. <br>
                        In the end, your satisfaction is what drives us!! </h5>
                    </div>
                </div>
            </div>
            <div class="container my-3">
                <div class="row my-5">
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 3120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >3D Animation</h5>
                                <small class="card-text font-textc">3D Computer Graphics (in contrast to 2D computer graphics) are graphics that use a three-dimensional representation....</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 5120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Visual Effect</h5>
                                <small class="card-text font-textc">Visual effects (commonly shortened to Visual F/X or VFX) are the various processes....</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 4120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Motion Graphic</h5>
                                <small class="card-text font-textc">No video is complete without some sort of graphics or text overlay. Examples of motion...</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 5120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Video Commercial</h5>
                                <small class="card-text font-textc">No video is complete without some sort of graphics or text overlay. Examples of motion...</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 3120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Website Development</h5>
                                <small class="card-text font-textc">3D Computer Graphics (in contrast to 2D computer graphics) are graphics that use a three-dimensional representation...</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 5120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Hosting</h5>
                                <small class="card-text font-textc">Visual effects (commonly shortened to Visual F/X or VFX) are the various processes....</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 4120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Social Management</h5>
                                <small class="card-text font-textc">No video is complete without some sort of graphics or text overlay. Examples of motion...</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 col-md-6 col-lg-3 mt-4">
                        <div class="card">
                            <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                <img src="../assets/images/service/Asset 5120.png" class="img-fluid m-5" alt="...">
                            </router-link>
                            <div class="text-center mx-5 mb-5">
                                <h5 class="tittlecard" >Google Ads</h5>
                                <small class="card-text font-textc">No video is complete without some sort of graphics or text overlay. Examples of motion...</small>
                                <router-link :to="{ name: 'detailservice'}" class="nav-link px-0 mt-0">
                                    <div class="mt-2"><a href="#" class="detail">Details</a></div>
                                </router-link>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.tittle-service{
    font-family: 'Avenir LT Std';
    color: #FFFFFF;
    font-weight: 900;
}
.content-service{
    font-family: 'Avenir LT Std';
    color: #FFFFFF;
    font-weight: 300;
}
.tittlecard{
    color: #003366;
    font-weight: 700;
    font-family: 'Avenir LT Std';
}

.font-textc{
    color: #999999;
    font-family: 'Avenir LT Std';
}

.detail{
    text-decoration: none;
    font-family: 'Avenir LT Std';
    color: #058CFF;
    font-weight: 500;
}

.service-margin{
   padding-top: 100px;
}

@media only screen and (max-width: 1000px) {
    .service-margin{
    padding-top: 95px;
    }
}
@media only screen and (max-width: 700px) {
    .service-margin{
    padding-top: 50px;
    }
}
</style>

<script>

export default {
    mounted () {
    window.scrollTo(0, 0)
  },
}
</script>
